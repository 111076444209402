<template>
    <el-container>
        <el-main>
            <el-form ref="form" label-width="160px" :rules="rules" :model="form">
                <el-form-item label="合同付费" prop="is_pay">
                    <el-switch v-model="form.is_pay" :active-value="1" :inactive-value="0"></el-switch>
                    <span style="margin-left:30px;">开启合同付费后，会员申请签订合同，则需要支付合同费用</span>
                </el-form-item>
                <el-form-item label="单次签订合同费用" prop="price">
                    <el-input class="inputClass" v-model="form.price" placeholder="请输入单次签订合同费用"></el-input>
                    <span style="margin-left:20px;">元/次</span>
                </el-form-item>
                <Preservation @preservation="submit">
                </Preservation>
            </el-form>
        </el-main>
    </el-container>
</template>

<script>
import { inspectFloat } from '@/util/verification'
import Preservation from '@/components/preservation'
export default {
    components: {
        Preservation
    },
    data(){
        return {
            form:{
                is_pay:1,
                price:''
            },
            rules:{
                price:[{ required: true, message: '请输入单次签订合同费用', trigger: 'blur' },
                { validator: (rule, value, callback) => {
                    if(!inspectFloat(value)){
                        callback(new Error('请输入正确金额'));
                    }else {
                        callback();
                    }
                }, trigger: 'blur' }]
            }
        }
    },
    created(){
        this.getInfo()
    },
    methods:{
        getInfo(){
            this.$axios.post(this.$api.gateway.proxy.setinfo)
            .then((res) => {
                if (res.code == 0) {
                    let info = res.result
                    this.form = {
                        is_pay:info.is_pay,
                        price:info.price
                    }
                    if(info.id){
                        this.form.id = info.id
                    }
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        submit(){
            let url = this.form.id?this.$api.gateway.proxy.setedit:this.$api.gateway.proxy.setadd
            this.$axios.post(url,this.form)
            .then((res) => {
                if (res.code == 0) {
                    this.$message.success('保存成功')
                     this.getInfo()
                } else {
                    this.$message.error(res.msg)
                }
            })
        }
    },
}
</script>

<style scoped lang="less">
.el-container {
    .el-main {
        height: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;
    }
}
    .inputClass{
        width: 700px;
    }
.halfinputClass{
      width:333px
}
.flex{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
</style>
